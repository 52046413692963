import { css, cva } from "../../styled-system/css";
import { HTMLStyledProps } from "../../styled-system/types/jsx";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  styles?: HTMLStyledProps<"button">;
  variant?: "outlined";
}

const button = cva({
  base: {
    borderColor: "#ffbd4a",
    borderRadius: "100px",
    borderStyle: "solid",
    borderWidth: "2px",
    color: "white",
    cursor: "pointer",
    display: "inline",
    fill: "#ffbd4a",
    fontFamily: "Montserrat, sans-serif",
    fontSize: "15px",
    fontWeight: "400",
    marginRight: "27px",
    padding: "8px 20px",
    textDecoration: "none",
  },
  variants: {
    visual: {
      outlined: {
        _hover: {
          backgroundColor: "#ffbd4a",
          color: "white",
        },
        backgroundColor: "transparent",
        color: "#ffbd4a",
      },
      solid: {
        _hover: {
          backgroundColor: "transparent",
          borderColor: "#ffbd4a",
          color: "white",
        },
        backgroundColor: "#ffbd4a",
        color: "white",
      },
    },
  },
});

export const Button = ({
  children,
  onClick,
  styles = {},
  variant,
}: ButtonProps) => {
  return (
    <button
      className={css(button.raw({ visual: variant }), styles)}
      onClick={onClick}
    >
      {children}
    </button>
  );
};
