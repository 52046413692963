import { Bar } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import { BusinessStatistics } from "../api/BusinessApi";
import { Module } from "../api/ModulesApi";
import PremiumIcon from "../img/Premium.svg";
import TooltipIcon from "../img/tooltipIcon.svg";
import { getModulesGraphData } from "./ModulesGraph";

export function SkillsGraph({
  locked,
  premium = false,
  skills,
  stats,
}: {
  locked?: boolean;
  premium?: boolean;
  skills: Module[];
  stats: BusinessStatistics;
}) {
  const { t } = useTranslation("hr-dashboard");
  const translationResponse = useTranslation("hr-dashboard");
  const skillsGraphData = locked
    ? {
        datasets: [
          {
            backgroundColor: "rgba(107, 152, 170, 1)",
            data: [60, 50, 40, 30, 20, 10],
            label: "locked data",
          },
        ],
        labels: [
          "Keuzestress",
          "Time management",
          "Communicatie",
          "Assertiviteit",
          "Dienend leiding geven",
          "Ergonomie",
        ],
      }
    : getModulesGraphData(skills, stats, translationResponse);

  const options = {
    aspectRatio: 1,
    barThickness: 10,
    indexAxis: "y" as const,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      tooltip: {
        backgroundColor: "#000000",
        bodyFont: {
          size: 12,
          weight: 700,
        },
        callbacks: {
          label: function (context: { parsed: { x: number } }) {
            return `${Number(context.parsed.x).toFixed(0)}`;
          },
          title: function () {
            return "";
          },
        },
        displayColors: false,
      },
    },
    responsive: true,
    scales: {
      x: {
        grid: {
          display: false,
        },
        title: {
          display: true,
          text: t("skillsGraph.xAxisTitle"),
        },
      },
      y: {
        grid: {
          display: false,
        },
        ticks: {
          font: {
            size: 11,
          },
        },
      },
    },
    ...(locked && { events: [] }),
  };

  return (
    <div className="graph">
      <div className={`${locked ? "locked-content" : ""}`}>
        <div className={"graph-title flex items-center"}>
          {t("skillsGraph.title")}
          <div
            className={"flex items-center"}
            data-tooltip-content={t("skillsGraph.tooltipContent")}
            data-tooltip-id="graph-explanation"
          >
            <img alt={""} className={"tooltip-icon"} src={TooltipIcon} />
          </div>
        </div>
        {skills.length === 0 ? (
          <div className={"ml-5 mt-4 mb-4"}>
            {t("skillsGraph.noDataMessage")}
          </div>
        ) : (
          <div className="graph-wrapper">
            <Bar
              data={skillsGraphData}
              options={options}
              style={{ maxHeight: "420px" }}
            />
          </div>
        )}
      </div>
      {premium ? (
        <img alt={""} className={"graph-premium"} src={PremiumIcon} />
      ) : null}
    </div>
  );
}
